// import axios from 'axios';
// import router from '@/router';
import {
  Notification,
  MessageBox
} from 'element-ui';
// // import { getToken } from '@/utils/auth';

// 创建axios实例
const baseURL = 'https://user.dasmindfit.com/api';
// const baseURL = 'http://192.168.0.112:8085/api';
const service = axios.create({
  // baseURL: 'http://test.zhihanyixing.com:8084', // api 的 base_url
  baseURL,
  timeout: 20000 // 请求超时时间
});

// // request拦截器
// service.interceptors.request.use(
//   config => {
//     if (sessionStorage.getItem('token')) {
//       config.headers['token'] = sessionStorage.getItem('token'); // 让每个请求携带自定义token 请根据实际情况自行修改
//     }
//     config.headers['Content-Type'] = 'application/json';
//     // config.headers['Content-Type'] = 'multipart/form-data';
//     // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
//     return config;
//   },
//   error => {
//     // Do something with request error
//     console.log(error); // for debug
//     Promise.reject(error);
//   }
// );

// // response 拦截器
// service.interceptors.response.use(
//   response => {
//     const code = response.data.code;
//     if (code < 200 || code > 300) {
//       // Notification.error({
//       //   title: response.data.msg
//       // });
//       // setTimeout(() => {
//       //   router.push({ path: '/' });
//       // }, 3000);
//       // eslint-disable-next-line prefer-promise-reject-errors
//       return Promise.reject('error');
//     } else {
//       // alert(JSON.stringify(response.data))
//       return response.data;
//     }
//   },
//   error => {
//     let code = 0;
//     try {
//       code = error.response.data.status;
//     } catch (e) {
//       if (error.toString().indexOf('Error: timeout') !== -1) {
//         Notification.error({
//           title: '网络请求超时',
//           duration: 5000
//         });
//         return Promise.reject(error);
//       }
//     }
//     if (code) {
//       if (code === 401) {
//         MessageBox.confirm(
//           '登录状态已过期，您可以继续留在该页面，或者重新登录',
//           '系统提示',
//           {
//             confirmButtonText: '重新登录',
//             cancelButtonText: '取消',
//             type: 'warning'
//           }
//         );
//       } else if (code === 403) {
//         router.push({ path: '/401' });
//       } else {
//         const errorMsg = error.response.data.message;
//         if (errorMsg !== undefined) {
//           Notification.error({
//             title: errorMsg,
//             duration: 5000
//           });
//         }
//       }
//     } else {
//       Notification.error({
//         title: '接口请求失败',
//         duration: 5000
//       });
//     }
//     return Promise.reject(error);
//   }
// );
// export default service;

import axios from 'axios'

// 引入路由对象
import router from "../router"

// import { ElMessageBox, ElMessage } from 'element-plus'


// 给axios添加请求拦截器：发送请求时，还没到后端，就把请求给拦截下来
service.interceptors.request.use((config) => {
  // console.log(config);
  // config.headers.Authorization = sessionStorage.token
  config.headers['token'] = localStorage.getItem('token'); // 让每个请求携带自定义token 请根据实际情况自行修改
  //     }
  config.headers['Content-Type'] = 'application/json';
  return config
}, (err) => {
  // console.log(err)
  return Promise.reject(err)
})
// 处理后端返回的报错信息：后端返回信息时，还没返回到前端，就把返回信息给拦截下来
// 给axios添加响应拦截器，有两个箭头函数，成功进入第一个箭头函数，失败进入第二个箭头函数
service.interceptors.response.use((response) => {
  // 响应成功
  // console.log(res);
  if(response.data.code === 40100) {
    Notification.error({
      title: '该账号已在别处登陆,请重新登陆!',
      duration: 5000
    });
    localStorage.clear();
    setTimeout(() => {
      router.push('/')
    }, 3000);
    // router.push('/')
  }
  const code = response.status;
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.data.msg
      });
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('error');
    } else {
      // alert(JSON.stringify(response.data))
      return response.data;
    }
  // return res.data

}, (err) => {
  // console.log(err);
  // 响应失败（后端返回了报错信息）
  if (err.response.data.status === 40100) {
    console.log('401')
    // Notification.error('登录已过期，请重新登录', '警告', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //           router.push('/login')
    //       }
    //     });
    // ElMessageBox.alert('登录已过期，请重新登录', '警告', {
    //   confirmButtonText: '确定',
    //   callback: (action) => {
    //       router.push('/login')
    //   },
    // })
  } else {
    return Promise.reject(err)
  }
})

export default service;