<template>
  <div class="login" v-if="$store.state.settingsInfo.loginPage === 1">
    <div class="logo">
      <!-- <img src="../assets/home/loginLogo.png" alt="" /> -->
      <img :src="$store.state.settingsInfo.loginLogo" alt="" />
    </div>
    <div class="content">
      <!-- <div style="position:absolute; right: calc(550px - 50% - 50px); top: 100px; color: #cc0022; font-size: 16px; font-weight: 600;">
				<span >心理测评系统</span></div> -->
      <!-- <div class="topName">
						<span>心理测评系统</span>
					</div> -->
      <div class="container">
        <div class="tableContent">
          <div class="topName">
            <span>心理测评系统</span>
          </div>
          <div class="tabs" style="position: relative">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="登录" name="loginFirst">
                <!-- 登录区域 -->
                <div class="loginContent">
                  <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                  >
                    <el-form-item prop="username">
                      <el-input
                        v-show="loginType === 'true'"
                        prefix-icon="el-icon-user"
                        placeholder="登录名/手机号"
                        v-model="ruleForm.username"
                      ></el-input>
                      <!-- <i class="el-icon-arrow-down" style="position:absolute;right:5px; font-size:36px; line-height:45px; color: #DCDFE6; font-weight: 600;"> -->
                      <el-dropdown
                        @command="handleCommand"
                        style="
                          position: absolute;
                          right: 5px;
                          font-size: 36px;
                          line-height: 45px;
                          color: #dcdfe6;
                          font-weight: 600;
                        "
                      >
                        <span class="el-dropdown-link">
                          <i
                            slot="prefix"
                            class="el-icon-arrow-down"
                            style="color: #c0c4cc"
                          ></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="true"
                            >密码登录</el-dropdown-item
                          >
                          <el-dropdown-item command="false"
                            >手机验证码登录</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                      <!-- </i> -->
                      <!-- <el-input v-show="loginType === 'true'" v-model="ruleForm.username"
												placeholder="登录名">
												<el-dropdown slot="prefix" @command="handleCommand">
													<span class="el-dropdown-link">
														<i slot="prefix" class="el-input__icon el-icon-user"
															style="color: #C0C4CC"></i>
													</span>
													<el-dropdown-menu slot="dropdown">
														<el-dropdown-item command='true'>登录名</el-dropdown-item>
														<el-dropdown-item command='false'>手机号</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown>
											</el-input> -->
                      <el-input
                        v-show="loginType === 'false'"
                        v-model="ruleForm.username"
                        placeholder="手机号"
                        prefix-icon="el-icon-user"
                        type="number"
                      />
                      <el-dropdown
                        @command="handleCommand"
                        style="
                          position: absolute;
                          right: 5px;
                          font-size: 36px;
                          line-height: 45px;
                          color: #dcdfe6;
                          font-weight: 600;
                        "
                      >
                        <span class="el-dropdown-link">
                          <i
                            slot="prefix"
                            class="el-icon-arrow-down"
                            style="color: #c0c4cc"
                          ></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="true"
                            >密码登录</el-dropdown-item
                          >
                          <el-dropdown-item command="false"
                            >手机验证码登录</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                      <!-- <i slot="prefix" class="el-input__icon el-icon-user" @click="choiceLogin"></i > -->
                      <!-- <el-dropdown slot="prefix" @command="handleCommand">
													<span class="el-dropdown-link">
														<i slot="prefix" class="el-input__icon el-icon-user"
															style="color: #C0C4CC"></i>
													</span>
													<el-dropdown-menu slot="dropdown">
														<el-dropdown-item command='true'>登录名</el-dropdown-item>
														<el-dropdown-item command='false'>手机号</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown> -->
                      <!-- </el-input> -->
                    </el-form-item>
                    <el-form-item prop="password">
                      <el-input
                        v-show="loginType === 'true'"
                        v-model="ruleForm.password"
                        prefix-icon="el-icon-lock"
                        placeholder="密码"
                        show-password
                      ></el-input>
                      <div class="codeContent" v-show="loginType === 'false'">
                        <el-input
                          v-model="ruleForm.password"
                          prefix-icon="el-icon-key"
                          placeholder="验证码"
                          show-telephone
                        ></el-input>
                        <div
                          class="code"
                          style="
                            position: absolute;
                            right: 10px;
                            top: 0px;
                            line-height: 45px;
                            cursor: pointer;
                          "
                        >
                          <span
                            v-if="!loginCode"
                            class="noCode"
                            @click="getCodeLogin"
                            >获取验证码</span
                          >
                          <span v-else class="hasCode"
                            >{{ loginCodeTime }}s 后重试</span
                          >
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        type="primary"
                        class="first"
                        @click="submitForm('ruleForm')"
                        >登录
                      </el-button>
                    </el-form-item>
                    <el-form-item
                      v-if="$store.state.settingsInfo.tourist === 1"
                    >
                      <el-button type="primary" plain @click="loginAuth"
                        >游客访问</el-button
                      >
                      <!-- <el-button class="second">注册</el-button> -->
                    </el-form-item>
                  </el-form>
                  <div class="forgetPwd" @click="forgetPwd">
                    <span>忘记密码</span>
                  </div>
                  <div class="anotherLogin" @click="isShowForm = true"
                    v-if="$store.state.settingsInfo.wechatLogin === 1"
                  >>
                    <div class="title">
                      <span class="solid"></span>
                      <span class="name">第三方登录</span>
                      <span class="solid"></span>
                    </div>
                  </div>

                  <div class="otherType" style="position: relative">
                    <div
                      style="
                        position: absolute;
                        top: -300px;
                        width: 300px;
                        height: 300px;
                        background-color: white;
                      "
                      v-show="!isShowForm"
                    >
                      <div id="weixin" class="qrcode"></div>
                    </div>

                    <img
                      v-if="$store.state.settingsInfo.wechatLogin === 1"
                      src="@/assets/home/WeChat.png"
                      @click="weixinClick"
                      style="z-index: 333333"
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="注册"
                name="registerSecond"
                v-if="isRegister === true"
              >
                <div class="registerContent" v-loading="loading">
                  <div class="registerHeight" v-show="!registerStatus">
                    <el-form
                      :model="RegisterForm"
                      :rules="registerRules"
                      ref="ruleFormRegister"
                      class="demo-ruleForm"
                    >
                      <div class="registerStyle">
                        <el-form-item prop="nickname">
                          <el-input
                            v-model="RegisterForm.nickname"
                            prefix-icon="el-icon-user"
                            placeholder="* 姓名"
                          ></el-input>
                        </el-form-item>
                        <el-form-item prop="username">
                          <el-input
                            v-model="RegisterForm.username"
                            prefix-icon="el-icon-user"
                            placeholder="* 登录名"
                          ></el-input>
                        </el-form-item>
                        <el-form-item prop="telephone">
                          <el-input
                            v-model="RegisterForm.telephone"
                            prefix-icon="el-icon-mobile-phone"
                            placeholder="* 手机号"
                            type="number"
                            show-telephone
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                          <div class="codeContent">
                            <el-input
                              v-model="RegisterForm.code"
                              prefix-icon="el-icon-key"
                              placeholder="* 验证码"
                              show-telephone
                            ></el-input>
                            <div class="code">
                              <span
                                v-if="!codeStatus"
                                class="noCode"
                                @click="getCode"
                                >获取验证码</span
                              >
                              <span v-else class="hasCode"
                                >{{ totalTime }}s 后重试</span
                              >
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item prop="password">
                          <el-input
                            v-model="RegisterForm.password"
                            prefix-icon="el-icon-lock"
                            placeholder="* 密码"
                            show-password
                          ></el-input>
                        </el-form-item>
                        <el-form-item prop="checkPass">
                          <el-input
                            v-model="RegisterForm.checkPass"
                            prefix-icon="el-icon-lock"
                            placeholder="* 确认密码"
                            show-password
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                      <!-- <el-form-item>
											<el-select v-model="RegisterForm.education" placeholder="请选择学历">
												<el-option label="初等教育" :value="1"></el-option>
												<el-option label="中等教育" :value="2"></el-option>
												<el-option label="高等教育" :value="3"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item>
											<el-select v-model="RegisterForm.occupation" placeholder="请选择职业">
												<el-option label="国家机关、党群组织、企业、事业单位负责人" :value="1"></el-option>
												<el-option label="专业技术人员" :value="2"></el-option>
												<el-option label="办事人员和有关人员" :value="3"></el-option>
												<el-option label="商业、服务业人员" :value="4"></el-option>
												<el-option label="农、林、牧、渔、水利业生产人员" :value="5"></el-option>
												<el-option label="生产、运输设备操作人员及有关人员" :value="6"></el-option>
												<el-option label="军人" :value="7"></el-option>
												<el-option label="学生" :value="8"></el-option>
												<el-option label="不便分类的其他从业人员" :value="9"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item v-show="RegisterForm.occupation === 8">
											<el-select v-model="RegisterForm.grade" placeholder="请选择年级">
												<el-option label="小学1-6" :value="1"></el-option>
												<el-option label="初中1-3" :value="2"></el-option>
												<el-option label="高中1-3" :value="3"></el-option>
												<el-option label="大学1-4" :value="4"></el-option>
											</el-select>
										</el-form-item> -->
                      <el-form-item prop="name">
                        <el-button
                          type="primary"
                          class="first"
                          @click="submit('ruleFormRegister')"
                        >
                          确认
                        </el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div v-show="registerStatus" class="successRegister">
                    <div class="img">
                      <img src="@/assets/home/success.png" alt="" />
                      <span class="font">注册成功</span>
                      <el-button type="primary" @click="toLogin">
                        返回登录
                      </el-button>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="position: fixed; right: 80px; bottom: 30px; text-align: right; font-size:12px; margin-top: 10px;">
			1.0测试版本</div> -->
  </div>
  <div class="login2" v-else>
    <div class="content">
      <div class="logo">
        <img :src="$store.state.settingsInfo.loginLogo" alt="" />
        <!-- <span style="position:absolute;  top: 100px; color: #cc0022; font-size: 16px; font-weight: 600;">心理测评系统</span> -->
      </div>
      <!-- <div style="position:absolute; right: calc(550px - 50% - 50px); top: 100px; color: #cc0022; font-size: 16px; font-weight: 600;">
				<span >心理测评系统</span></div> -->
      <div class="container">
        <div class="tableContent">
          <!-- <div style="text-align: center; color: #cc0022; font-size: 16px; font-weight: 600;">
						<span>心理测评系统</span>
					</div> -->
          <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="登录" name="loginFirst">
                <!-- 登录区域 -->
                <div class="loginContent">
                  <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                  >
                    <el-form-item prop="username">
                      <el-input
                        v-show="loginType === 'true'"
                        v-model="ruleForm.username"
                        prefix-icon="el-icon-user"
                        placeholder="登录名/手机号"
                      >
                      </el-input>
                      <el-dropdown
                        @command="handleCommand"
                        style="
                          position: absolute;
                          right: 5px;
                          font-size: 36px;
                          line-height: 45px;
                          color: #dcdfe6;
                          font-weight: 600;
                        "
                      >
                        <span class="el-dropdown-link">
                          <i
                            slot="prefix"
                            class="el-icon-arrow-down"
                            style="color: #c0c4cc"
                          ></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="true"
                            >密码登录</el-dropdown-item
                          >
                          <el-dropdown-item command="false"
                            >手机验证码登录</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                      <el-input
                        v-show="loginType === 'false'"
                        v-model="ruleForm.username"
                        prefix-icon="el-icon-user"
                        placeholder="手机号"
                        type="number"
                      >
                        <!-- <i slot="prefix" class="el-input__icon el-icon-user" @click="choiceLogin"></i > -->
                      </el-input>
                      <el-dropdown
                        @command="handleCommand"
                        style="
                          position: absolute;
                          right: 5px;
                          font-size: 36px;
                          line-height: 45px;
                          color: #dcdfe6;
                          font-weight: 600;
                        "
                      >
                        <span class="el-dropdown-link">
                          <i
                            slot="prefix"
                            class="el-icon-arrow-down"
                            style="color: #c0c4cc"
                          ></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="true"
                            >密码登录</el-dropdown-item
                          >
                          <el-dropdown-item command="false"
                            >手机验证码登录</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-form-item>
                    <el-form-item prop="password">
                      <el-input
                        v-show="loginType === 'true'"
                        v-model="ruleForm.password"
                        prefix-icon="el-icon-lock"
                        placeholder="密码"
                        show-password
                      ></el-input>
                      <div class="codeContent" v-show="loginType === 'false'">
                        <el-input
                          v-model="ruleForm.password"
                          prefix-icon="el-icon-key"
                          placeholder="验证码"
                          show-telephone
                        ></el-input>
                        <div
                          class="code"
                          style="
                            position: absolute;
                            right: 10px;
                            top: 0px;
                            line-height: 45px;
                            cursor: pointer;
                          "
                        >
                          <span
                            v-if="!loginCode"
                            class="noCode"
                            @click="getCodeLogin"
                            >获取验证码</span
                          >
                          <span v-else class="hasCode"
                            >{{ loginCodeTime }}s 后重试</span
                          >
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        type="primary"
                        class="first"
                        @click="submitForm('ruleForm')"
                        >登录
                      </el-button>
                    </el-form-item>
                    <el-form-item
                      v-if="$store.state.settingsInfo.tourist === 1"
                    >
                      <el-button type="primary" plain @click="loginAuth"
                        >游客访问</el-button
                      >
                      <!-- <el-button class="second">注册</el-button> -->
                    </el-form-item>
                  </el-form>
                  <div class="forgetPwd" @click="forgetPwd">
                    <span>忘记密码</span>
                  </div>
                  <div
                    class="anotherLogin"
                    v-if="$store.state.settingsInfo.wechatLogin === 1"
                  >
                    <div class="title">
                      <span class="solid"></span>
                      <span class="name">第三方登录</span>
                      <span class="solid"></span>
                    </div>
                  </div>
                  <div class="otherType" style="position: relative">
                    <div
                      style="
                        position: absolute;
                        top: -300px;
                        width: 300px;
                        height: 300px;
                        background-color: white;
                      "
                      v-show="!isShowForm"
                    >
                      <div id="weixin" class="qrcode"></div>
                    </div>

                    <img
                      v-if="$store.state.settingsInfo.wechatLogin === 1"
                      src="@/assets/home/WeChat.png"
                      @click="weixinClick"
                      style="z-index: 333333"
                    />
                  </div>
                  <!-- <div class="otherType"> -->
                  <!-- <img src="@/assets/home/QQ.png" alt=""> -->
                  <!-- <img src="@/assets/home/WeChat.png" alt="" v-if="$store.state.settingsInfo.wechatLogin === 1"> -->
                  <!-- <img src="@/assets/home/weibo.png" alt=""> -->
                  <!-- </div> -->
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="注册"
                name="registerSecond"
                v-if="isRegister === true"
              >
                <div class="registerContent" v-loading="loading">
                  <div class="registerHeight" v-show="!registerStatus">
                    <el-form
                      :model="RegisterForm"
                      :rules="registerRules"
                      ref="ruleFormRegister"
                      class="demo-ruleForm"
                    >
                      <div class="registerStyle">
                        <el-form-item prop="nickname">
                          <el-input
                            v-model="RegisterForm.nickname"
                            prefix-icon="el-icon-user"
                            placeholder="* 姓名"
                          ></el-input>
                        </el-form-item>
                        <el-form-item prop="username">
                          <el-input
                            v-model="RegisterForm.username"
                            prefix-icon="el-icon-user"
                            placeholder="* 登录名"
                          ></el-input>
                        </el-form-item>
                        <el-form-item prop="telephone">
                          <el-input
                            v-model="RegisterForm.telephone"
                            prefix-icon="el-icon-mobile-phone"
                            placeholder="* 手机号"
                            type="number"
                            show-telephone
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                          <div class="codeContent">
                            <el-input
                              v-model="RegisterForm.code"
                              prefix-icon="el-icon-key"
                              placeholder="* 验证码"
                              show-telephone
                            ></el-input>
                            <div class="code">
                              <span
                                v-if="!codeStatus"
                                class="noCode"
                                @click="getCode"
                                >获取验证码</span
                              >
                              <span v-else class="hasCode"
                                >{{ totalTime }}s 后重试</span
                              >
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item prop="password">
                          <el-input
                            v-model="RegisterForm.password"
                            prefix-icon="el-icon-lock"
                            placeholder="* 密码"
                            show-password
                          ></el-input>
                        </el-form-item>
                        <el-form-item prop="checkPass">
                          <el-input
                            v-model="RegisterForm.checkPass"
                            prefix-icon="el-icon-lock"
                            placeholder="* 确认密码"
                            show-password
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                      <!-- <el-form-item>
											<el-select v-model="RegisterForm.education" placeholder="请选择学历">
												<el-option label="初等教育" :value="1"></el-option>
												<el-option label="中等教育" :value="2"></el-option>
												<el-option label="高等教育" :value="3"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item>
											<el-select v-model="RegisterForm.occupation" placeholder="请选择职业">
												<el-option label="国家机关、党群组织、企业、事业单位负责人" :value="1"></el-option>
												<el-option label="专业技术人员" :value="2"></el-option>
												<el-option label="办事人员和有关人员" :value="3"></el-option>
												<el-option label="商业、服务业人员" :value="4"></el-option>
												<el-option label="农、林、牧、渔、水利业生产人员" :value="5"></el-option>
												<el-option label="生产、运输设备操作人员及有关人员" :value="6"></el-option>
												<el-option label="军人" :value="7"></el-option>
												<el-option label="学生" :value="8"></el-option>
												<el-option label="不便分类的其他从业人员" :value="9"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item v-show="RegisterForm.occupation === 8">
											<el-select v-model="RegisterForm.grade" placeholder="请选择年级">
												<el-option label="小学1-6" :value="1"></el-option>
												<el-option label="初中1-3" :value="2"></el-option>
												<el-option label="高中1-3" :value="3"></el-option>
												<el-option label="大学1-4" :value="4"></el-option>
											</el-select>
										</el-form-item> -->
                      <el-form-item prop="name">
                        <el-button
                          type="primary"
                          class="first"
                          @click="submit('ruleFormRegister')"
                        >
                          确认
                        </el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div v-show="registerStatus" class="successRegister">
                    <div class="img">
                      <img src="@/assets/home/success.png" alt="" />
                      <span class="font">注册成功</span>
                      <el-button type="primary" @click="toLogin">
                        返回登录
                      </el-button>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="position: fixed; right: 80px; bottom: 30px; text-align: right; font-size:12px; margin-top: 10px;">
			1.0测试版本</div> -->
  </div>
</template>


<script>
import { mapActions } from "vuex";
import {
  login,
  authLogin,
  getLoginCode,
  loginTel,
  weChatLogin,
  weChatCode,
  weChatCodes,
  weChatInfos,
} from "@/api/login";
import { register, getCodes } from "@/api/register";
import { getSetting } from "@/api/setting";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.RegisterForm.checkPass !== "") {
          this.$refs.ruleFormRegister.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.RegisterForm.password) {
        callback(new Error("密码输入不一致，请检查并重新输入!"));
      } else {
        callback();
      }
    };
    return {
      isShowForm: true,
      activeName: "loginFirst",
      settingInfo: {}, //配置信息
      ruleForm: {
        username: null,
        password: null,
      },
      loginType: "true", // true.为登录名登录， false为手机号登录
      RegisterForm: {
        nickname: null, // 姓名
        username: null, // 登录名
        telephone: null, // 电话
        password: null, // 密码
        code: null, // 验证码
        departmentId: null, // 注册用户所属组织机构
        // occupation: null, //职业
        // education: null, // 学历
        // grade: null, // 年级
        // checkPass: null // 确认密码
      },
      rules: {
        username: [
          { required: true, message: "请输入登录名/手机号！", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: "请输入密码/验证码！", trigger: "blur" },
        ],
      },
      registerRules: {
        password: [
          { validator: validatePass, trigger: "blur" },
          { required: true, message: "请输入密码！", trigger: "blur" },
        ],
        checkPass: [
          { validator: validatePass2, trigger: "change" },
          { required: true, message: "请再次输入密码！", trigger: "blur" },
        ],
        nickname: [
          { required: true, message: "请输入姓名！", trigger: "blur" },
          { max: 15, message: "长度在15个字符以内", trigger: "change" },
        ],
        username: [
          { required: true, message: "请输入登录名！", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "请输入手机号！", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "请输入正确的手机号",
            trigger: "change",
          },
        ],
        code: [{ required: true, message: "请输入验证码！", trigger: "blur" }],
      },
      registerStatus: false, // 判断用户是否注册成功
      codeStatus: false, // 判断用户是否点击获取验证码
      totalTime: 60, // 验证的的时长
      loading: false, // 加载动画
      planId: null, // 计划的id
      loginCode: false, // 判断用户是否点击获取登录验证码
      loginCodeTime: 60, // 登录验证码时长
      isRegister: true, // 是否允许注册
    };
  },
  methods: {
    ...mapActions(["getInfo"]),
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.ruleForm);
          // 判断用户是否为扫码用户
          if (this.isCode === true) {
            this.ruleForm = {
              isCode: true,
              planId: this.planId,
              ...this.ruleForm,
            };
          }
          // 用户名登录
          if (this.loginType === "true") {
            const res = await login(JSON.stringify(this.ruleForm));
            console.log(res);
            if (res.code === 200) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("userInfo", JSON.stringify(res.data));
              localStorage.setItem("roleId", res.data.roleId);
              this.$message({
                showClose: true,
                message: "登录成功！",
                type: "success",
              });
              // localStorage.setItem('token', res.data.token)
              setTimeout(() => {
                this.$router.push("/home/index");
                // console.log(JSON.parse(localStorage.getItem('userInfo')));
                let info = JSON.parse(localStorage.getItem("userInfo"));
                localStorage.setItem("roleId", res.data.roleId);
                if (
                  info.birthday &&
                  info.nickname &&
                  info.sex &&
                  info.telephone
                ) {
                } else {
                  this.$alert(
                    "您的信息未填写完整，请您至少补充性别、年龄信息，报告结果才更准确！",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      callback: (action) => {
                        console.log(action);
                        // this.$message({
                        // 	type: 'info',
                        // 	message: `${action}`
                        // });
                      },
                    }
                  );
                  // this.$confirm('你的信息未填写完整，请您尽快完善！', '提示', {
                  // 	confirmButtonText: '确定',
                  // 	  cancelButtonText: '否',
                  // 	type: 'warning',
                  // 	center: true,
                  // 	showClose: false
                  // }).then(async () => {
                  // }).catch(() => {
                  // });
                }
              }, 1000);
            } else {
              this.$message({
                showClose: true,
                message: "登录失败！" + res.msg,
                type: "error",
              });
            }
          } else {
            // 手机号登录
            let data = new FormData();
            data.append("telephone", this.ruleForm.username);
            data.append("code", this.ruleForm.password);
            const res = await loginTel(data);
            console.log(res);
            if (res.code === 200) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("userInfo", JSON.stringify(res.data));
              this.$message({
                showClose: true,
                message: "登录成功！",
                type: "success",
              });
              // localStorage.setItem('token', res.data.token)
              setTimeout(() => {
                this.$router.push("/home/index");
                let info = JSON.parse(localStorage.getItem("userInfo"));
                if (
                  info.birthday &&
                  info.nickname &&
                  info.sex &&
                  info.telephone
                ) {
                } else {
                  this.$alert(
                    "您的信息未填写完整，请您至少补充性别、年龄信息，报告结果才更准确！",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      callback: (action) => {
                        console.log(action);
                        // this.$message({
                        // 	type: 'info',
                        // 	message: `${action}`
                        // });
                      },
                    }
                  );
                  // this.$confirm('你的信息未填写完整，请您尽快完善！', '提示', {
                  // 	confirmButtonText: '确定',
                  // 	  cancelButtonText: '否',
                  // 	type: 'warning',
                  // 	center: true,
                  // 	showClose: false
                  // }).then(async () => {
                  // }).catch(() => {
                  // });
                }
              }, 1000);
            } else {
              this.$message({
                showClose: true,
                message: "登录失败！" + res.msg,
                type: "error",
              });
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取注册验证码
    async getCode() {
      // console.log(this.RegisterForm.telephone);

      if (
        this.RegisterForm.telephone !== null &&
        this.RegisterForm.telephone !== ""
      ) {
        this.codeStatus = true;
        let clock = window.setInterval(() => {
          this.totalTime--;
          if (this.totalTime < 0) {
            //当倒计时小于0时清除定时器
            window.clearInterval(clock); //关
            this.totalTime = 60;
            this.codeStatus = false;
          }
        }, 1000);
        const res = await getCodes({ telephone: this.RegisterForm.telephone });
        console.log(res);
		if (res.code !== 200) {
          this.$message({
            showClose: true,
            message: res.msg,
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: "请输入手机号！",
        });
      }
    },
    // 获取登录验证码
    async getCodeLogin() {
      // console.log(this.RegisterForm.telephone);
      if (this.ruleForm.username !== null && this.ruleForm.username !== "") {
        this.loginCode = true;
        let clock = window.setInterval(() => {
          this.loginCodeTime--;
          if (this.loginCodeTime < 0) {
            //当倒计时小于0时清除定时器
            window.clearInterval(clock); //关
            this.loginCodeTime = 60;
            this.loginCode = false;
          }
        }, 1000);
        const res = await getLoginCode({ telephone: this.ruleForm.username });
        console.log(res);
        if (res.code !== 200) {
          this.$message({
            showClose: true,
            message: res.msg,
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: "请输入手机号！",
        });
      }
    },
    // 注册
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let info = JSON.parse(JSON.stringify(this.RegisterForm));
          // delete info.code;
          delete info.checkPass;
          console.log(info);
          // info.source = 2;
          // alert('submit!');
          setTimeout(() => {
            this.loading = false;
          }, 500);
          if (this.planId === "undefine") {
            this.planId = null;
          }
          info = { ...info, planId: this.planId };
          console.log(info);
          const res = await register(JSON.stringify(info));
          console.log(res);
          if (res.code === 200) {
            // this.$message({
            // 	showClose: true,
            // 	message: '注册成功',
            // 	type: 'success'
            // });

            this.RegisterForm = {
              nickname: null, // 姓名
              username: null, // 登录名
              telephone: null, // 电话
              password: null, // 密码
              code: null, // 验证码
              checkPass: null, // 确认密码
            };
            this.codeStatus = false;
            this.totalTime = 60;
            this.registerStatus = true;
          } else {
            this.$message({
              showClose: true,
              message: "注册失败！" + res.msg,
              type: "error",
            });
          }
        } else {
          console.log("error submit!!");
          this.$message({
            showClose: true,
            message: "请将信息填写完整！",
          });
          return false;
        }
      });
    },
    // 返回登录
    toLogin() {
      this.activeName = "loginFirst";
      this.registerStatus = false;
    },
    // 点击忘记密码
    forgetPwd() {
      this.$router.push("/verification");
    },
    // 游客登录
    async loginAuth() {
      let info = { isCode: this.isCode, planId: Number(this.planId) };
      console.log(info);
      const res = await authLogin(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        // localStorage.setItem('patientId', 'null')
        localStorage.setItem("roleId", 4);
        this.$message({
          showClose: true,
          message: "登录成功！",
          type: "success",
        });
        // localStorage.setItem('token', res.data.token)
        setTimeout(() => {
          this.$router.push("/home/index");
        }, 1000);
      }
    },
    handleCommand(val) {
      console.log(val);
      this.ruleForm.username = null;
      this.ruleForm.password = null;
      this.loginType = val;
      console.log(this.loginType);
    },
    async weixinClick() {
      // 点击切换微信扫码登录这一项，并向微信扫码登录

      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      {
        //判断是否是微信浏览器
        var ua = navigator.userAgent.toLowerCase();
        // 1.是微信内置浏览器
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          //获取当前页面的地址
          // this.weChatInfo(2);
          const res = await weChatInfos(2);
          console.log(res);
          if (res.code === 200) {
            this.settingInfo = res.data;
            let local = window.location.href;
            //调接微信官方生成授权登录的url appid必须填写
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.settingInfo.appid}&redirect_uri=${this.settingInfo.redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
            // "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx215d0cf437136a7c&redirect_uri=http://xbssc.zhihanyixing.com" +
            // encodeURIComponent(local) +
            // "&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
            //这一步是跳转这上方定义的url
            window.location.href = url;
          }

          // 2.非微信内置
        } else {
          // this.weChatInfo(1);
          const res = await weChatInfos(1);
          console.log(res);
          if (res.code === 200) {
            this.settingInfo = res.data;
            this.isShowForm = !this.isShowForm;
            // if(this.isShowForm === true) {
            // 	this.isShowForm = false;
            // } else {
            // 	this.isShowForm = true;
            // }
            console.log(this.isShowForm);
            // this.isShowForm = !this.isShowForm;
            new WxLogin({
              id: "weixin",
              appid: `${this.settingInfo.appid}`, // 这个appid要填死
              scope: "snsapi_login",
              // 扫码成功后重定向的接口
              // redirect_uri: "http://xbssc.zhihanyixing.com/home/index"
              redirect_uri: `${this.settingInfo.redirectUrl}`,
              href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6MjUwcHg7fQ0KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5zdGF0dXMge3BhZGRpbmc6IDBweDt9",
              // state填写编码后的url
              // state: encodeURIComponent(window.btoa("http://127.0.0.1:8080" + _this.$route.path))
            });
          }
          //   this.$message.fail("请在微信浏览器下使用此功能");
          // console.log('请在微信浏览器下使用此功能');
        }
      }
    },
    //截取地址栏code
    getUrlCode(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    //把code传递给后台接口，静默登录
    async sendCode() {
      console.log(window.location.search);
      let mycode = window.location.search.split("=")[1];
      console.log(window.location.search.split("="));
      if (mycode) {
        //手机扫码登录
        let res = await weChatCode({
          code: mycode,
        });
        console.log(res);
        if (res.code === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          localStorage.setItem("roleId", res.data.roleId);
          this.$message({
            showClose: true,
            message: "登录成功！",
            type: "success",
          });
          // localStorage.setItem('token', res.data.token)
          setTimeout(() => {
            this.$router.push("/home/index");
            // console.log(JSON.parse(localStorage.getItem('userInfo')));
            let info = JSON.parse(localStorage.getItem("userInfo"));
            // localStorage.setItem('roleId', res.data.roleId);
            if (info.birthday && info.nickname && info.sex && info.telephone) {
            } else {
              this.$alert(
                "您的信息未填写完整，请您至少补充性别、年龄信息，报告结果才更准确！",
                "提示",
                {
                  confirmButtonText: "确定",
                  callback: (action) => {
                    console.log(action);
                  },
                }
              );
            }
          }, 1000);
        } else {
          this.$message({
            showClose: true,
            message: "登录失败！" + res.msg,
            type: "error",
          });
        }
      }
    },
    async sendCodes() {
      console.log(window.location.search);
      let mycode = window.location.search.split("=")[1];
      console.log(window.location.search.split("="));
      if (mycode) {
        //手机扫码登录
        let res = await weChatCodes({
          code: mycode,
        });
        console.log(res);
        if (res.code === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          localStorage.setItem("roleId", res.data.roleId);
          this.$message({
            showClose: true,
            message: "登录成功！",
            type: "success",
          });
          // localStorage.setItem('token', res.data.token)
          setTimeout(() => {
            this.$router.push("/home/index");
            // console.log(JSON.parse(localStorage.getItem('userInfo')));
            let info = JSON.parse(localStorage.getItem("userInfo"));
            // localStorage.setItem('roleId', res.data.roleId);
            if (info.birthday && info.nickname && info.sex && info.telephone) {
            } else {
              this.$alert(
                "您的信息未填写完整，请您至少补充性别、年龄信息，报告结果才更准确！",
                "提示",
                {
                  confirmButtonText: "确定",
                  callback: (action) => {
                    console.log(action);
                  },
                }
              );
            }
          }, 1000);
        } else {
          this.$message({
            showClose: true,
            message: "登录失败！" + res.msg,
            type: "error",
          });
        }
      }
    },
    // 获取配置信息
    async getLogs() {
      const res = await getSetting();
      console.log(res);
      if (res.code === 200) {
        // this.settingInfo = res.data;
        // sessionStorage.setItem('banner', res.data.bannerLogo);
        // sessionStorage.setItem('reportUrl', res.data.reportUrl)
      }
    },
    // 获取微信授权登录需要的信息
    async weChatInfo(type) {
      const res = await weChatInfos(type);
      console.log(res);
      if (res.code === 200) {
        this.settingInfo = res.data;
      }
    },
  },
  created() {
    this.getInfo();
    //判断是否是组织机构注册链接，如果是组织机构注册链接则该链接包含isRegister字段     获取计划id
    if (window.location.search.indexOf("id") !== -1) {
      // console.log(window.location.search.indexOf('id'));
      // console.log(window.location.search.split('&'))
      // let id = (window.location.search.split('&'))[0]
      // console.log(id.split('=')[1])
      // console.log(window.location.search.split('=')[1].slice(0, 1));
      this.planId = window.location.search.split("=")[1];
    } else if (window.location.search.indexOf("isRegister") !== -1) {
      // 组织机构注册链接
      if (window.location.search.split("=")[1].slice(0, 1) === "0") {
        this.isRegister = false;
      }
      this.RegisterForm.departmentId = window.location.search.split("=")[2];
      // console.log(this.RegisterForm.departmentId );
    }
    // 计划注册链接
    if (window.location.search.indexOf("isScan") !== -1) {
      console.log(window.location.search.split("&"));
      let Scan = window.location.search.split("&")[1];
      let scan = Scan.split("=")[1];
      let isRegister = window.location.search.split("=")[3];
      console.log(scan, isRegister);
      if (scan === 1 || scan === "1") {
        this.isCode = true;
      } else {
        this.isCode = false;
      }
      // 通过计划链接过来的，如果没有选择注册组织机构所属，则没有注册选项
      if (isRegister === 0 || isRegister === "0") {
        this.isRegister = false;
      } else {
        this.isRegister = true;
      }
      // console.log(isRegister)
    } else {
      this.isCode = false;
    }

    if (this.planId && this.planId.indexOf("&") !== -1) {
      let index = Number(this.planId.indexOf("&"));
      this.planId = this.planId.slice(0, index);
      console.log(this.planId);
    }

    // console.log(window.location.search.indexOf('code'));
    // 判断是不是微信登录
    if (window.location.search.indexOf("code") !== -1) {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      {
        //判断是否是微信浏览器
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          this.sendCodes();
        } else {
          this.sendCode();
        }
      }
    } else {
      // console.log(window.location.href);
      localStorage.setItem("location", JSON.stringify(window.location.href));
    }
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-cascader {
  width: 100%;
}

@media screen and (min-width: 751px) {
  .login {
    background-color: var(--theme-color);
    // background-image: url("../assets/home/backgroundImage.png");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    // background-color: antiquewhite;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    // display: flex;
    box-sizing: border-box;
    // padding-top: 100px;
    // align-items: center;

    // padding: 180px;
    // position: absolute;
    // justify-content: center;
    .topName {
      position: absolute;
      // text-align: center;
      width: 440px;
      top: 60px;
      font-size: 32px;
      font-weight: 600;
      color: var(--custom-color);
      display: flex;
      justify-content: center;
    }

    .logo {
      // position: fixed;
      padding-top: 50px;
      padding-left: 60px;
      margin-bottom: 20px;

      img {
        height: 60px;
      }
    }

    .registerStyle {
      height: 250px;
      overflow-y: scroll;
    }

    .content {
      position: relative;
      display: flex;
      justify-content: center;
      // margin: auto;
      // height: 100%;
      // overflow-y: scroll;
      // width: 94%;

      .container {
        // width: 100%;
        width: 550px;
        // height: 614px;
        background-color: hsla(0,0%,100%,.5);
        border-radius: 12px;
        // opacity: 0.5;
        filter: blur(0px);
        padding: 50px 55px;
        padding-top: 120px;
        box-sizing: border-box;

        .tableContent {
          .tabs {
            .el-button {
              width: 100%;
              // font-size: 26px;
              border-radius: 4px;
              box-sizing: border-box;
            }

            .el-form-item {
              &:last-child {
                margin-bottom: 10px;
              }
            }

            .loginContent {
              margin-top: 5px;
              box-sizing: border-box;

              // .el-input--prefix .el-input__inner {
              // 	height: 45px !important;
              // }

              // .el-button {
              // 	width: 100%;
              // 	font-size: 16px;
              // 	border-radius: 4px;
              // 	box-sizing: border-box;
              // }

              .first {
                margin-top: 20px;
              }

              // .el-form-item {
              // 	&:last-child {
              // 		margin-bottom: 10px;
              // 	}
              // }

              // .first {
              // 	background: var(--custom-color);
              // 	color: white;
              // }

              // .second {
              // 	box-sizing: border-box;
              // 	// background: #FFFFFF;
              // 	border: 1px solid var(--custom-color);

              // 	// color: var(--custom-color);
              // }
              .forgetPwd {
                // display: inline-block;
                margin-bottom: 20px;
                text-align: right;
                font-size: 12px;
                font-weight: 400;
                color: var(--custom-color);
                cursor: pointer;
              }

              .anotherLogin {
                .title {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .solid {
                    display: inline-block;
                    height: 1px;
                    width: 140px;
                    background: #7d7d7d;
                  }

                  .name {
                    text-align: center;
                    display: inline-block;
                    width: 80px;
                    flex: 1;
                    margin: 0px 20px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #7d7d7d;
                  }
                }
              }

              .otherType {
                display: flex;
                justify-content: center;
                margin-top: 20px;

                img {
                  height: 60px;
                  width: 60px;
                  display: inline-block;
                  margin: 0px 22px;

                  &:hover {
                    // height: 55px;
                    // width: 55px;
                    cursor: pointer;
                  }
                }
              }
            }

            .registerContent {
              .el-button {
                margin-top: 20px;
              }

              .codeContent {
                position: relative;

                .code {
                  position: absolute;
                  top: 0;
                  right: 0px;
                  display: flex;
                  height: 100%;
                  align-items: center;
                  // height: 100%;
                  // width: 100px;
                  // border-left: 1px solid #E6E6E6;
                  // text-align: center;
                  // line-height: 45px;

                  span {
                    display: inline-block;
                    line-height: 26px;
                    width: 90px;
                    height: 26px;
                    border-left: 1px solid #e6e6e6;
                    font-size: 12px;
                    font-weight: 400;
                    // color: var(--custom-color);
                    padding-left: 14px;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }

              .successRegister {
                padding-top: 50px;

                .img {
                  text-align: center;

                  img {
                    height: 100px;
                    width: 100px;
                  }

                  .font {
                    display: block;
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #303030;
                    margin-bottom: 66px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .login2 {
    background-color: var(--theme-color);
    // background-image: url("../assets/home/backgroundImage.png");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    // background-color: antiquewhite;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    padding-top: 100px;
    // align-items: center;

    // padding: 180px;
    // position: absolute;
    justify-content: center;

    .registerStyle {
      height: 250px;
      overflow-y: scroll;
    }

    .content {
      position: relative;
      // height: 100%;
      // overflow-y: scroll;
      // width: 94%;

      .logo {
        z-index: 2;
        text-align: center;
        line-height: 160px;
        position: absolute;
        top: -80px;
        left: calc(550px - 50% - 80px);
        // position: relative;
        width: 160px;
        height: 160px;
        background: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.36);
      }

      .container {
        // width: 100%;
        width: 550px;
        // height: 614px;
        background-color: hsla(0,0%,100%,.5);
        border-radius: 12px;
        // opacity: 0.5;
        filter: blur(0px);
        padding: 50px 55px;
        padding-top: 120px;
        box-sizing: border-box;

        .tableContent {
          .tabs {
            .el-button {
              width: 100%;
              // font-size: 26px;
              border-radius: 4px;
              box-sizing: border-box;
            }

            .el-form-item {
              &:last-child {
                margin-bottom: 10px;
              }
            }

            .loginContent {
              margin-top: 5px;
              box-sizing: border-box;

              // .el-input--prefix .el-input__inner {
              // 	height: 45px !important;
              // }

              // .el-button {
              // 	width: 100%;
              // 	font-size: 16px;
              // 	border-radius: 4px;
              // 	box-sizing: border-box;
              // }

              .first {
                margin-top: 20px;
              }

              // .el-form-item {
              // 	&:last-child {
              // 		margin-bottom: 10px;
              // 	}
              // }

              // .first {
              // 	background: var(--custom-color);
              // 	color: white;
              // }

              // .second {
              // 	box-sizing: border-box;
              // 	// background: #FFFFFF;
              // 	border: 1px solid var(--custom-color);

              // 	// color: var(--custom-color);
              // }
              .forgetPwd {
                // display: inline-block;
                margin-bottom: 20px;
                text-align: right;
                font-size: 12px;
                font-weight: 400;
                color: var(--custom-color);
                cursor: pointer;
              }

              .anotherLogin {
                .title {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .solid {
                    display: inline-block;
                    height: 1px;
                    width: 140px;
                    background: #7d7d7d;
                  }

                  .name {
                    text-align: center;
                    display: inline-block;
                    width: 80px;
                    flex: 1;
                    margin: 0px 20px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #7d7d7d;
                  }
                }
              }

              .otherType {
                display: flex;
                justify-content: center;
                margin-top: 20px;

                img {
                  height: 60px;
                  width: 60px;
                  display: inline-block;
                  margin: 0px 22px;

                  &:hover {
                    // height: 55px;
                    // width: 55px;
                    cursor: pointer;
                  }
                }
              }
            }

            .registerContent {
              .el-button {
                margin-top: 20px;
              }

              .codeContent {
                position: relative;

                .code {
                  position: absolute;
                  top: 0;
                  right: 0px;
                  display: flex;
                  height: 100%;
                  align-items: center;
                  // height: 100%;
                  // width: 100px;
                  // border-left: 1px solid #E6E6E6;
                  // text-align: center;
                  // line-height: 45px;

                  span {
                    display: inline-block;
                    line-height: 26px;
                    width: 90px;
                    height: 26px;
                    border-left: 1px solid #e6e6e6;
                    font-size: 12px;
                    font-weight: 400;
                    // color: var(--custom-color);
                    padding-left: 14px;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }

              .successRegister {
                padding-top: 50px;

                .img {
                  text-align: center;

                  img {
                    height: 100px;
                    width: 100px;
                  }

                  .font {
                    display: block;
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #303030;
                    margin-bottom: 66px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .login {
    // padding-top: 100px;
    // background-image: url('../assets/home/backgroundImage.png');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    // background-color: antiquewhite;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    // display: flex;
    box-sizing: border-box;

    // align-items: center;
    // // padding: 180px;
    // justify-content: center;
    .topName {
      margin-bottom: 40px;
      font-size: 20px;
      text-align: center;
    }

    .logo {
      // position: fixed;
      padding-top: 20px;
      padding-left: 20px;
      margin-bottom: 40px;

      img {
        height: 30px;
      }
    }

    .el-button {
      width: 100%;
      border-radius: 4px;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      // margin: auto;
      // display: flex;
      // justify-content: center;
      // position: relative;

      .container {
        width: 96%;
        margin: auto;

        .loginContent {
          margin-top: 5px;
          box-sizing: border-box;

          .first {
            margin-top: 20px;
          }

          .forgetPwd {
            text-align: right;
            font-size: 12px;
            font-weight: 400;
            color: var(--custom-color);
            cursor: pointer;
            margin-bottom: 20px;
          }

          .anotherLogin {
            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .solid {
                display: inline-block;
                height: 1px;
                width: 120px;
                background: #7d7d7d;
              }

              .name {
                text-align: center;
                display: inline-block;
                width: 100px;
                flex: 1;
                margin: 0px 20px;
                font-size: 12px;
                font-weight: 400;
                color: #7d7d7d;
              }
            }
          }

          .otherType {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            img {
              height: 50px;
              width: 50px;
              display: inline-block;
              margin: 0px 22px;

              &:hover {
                // height: 55px;
                // width: 55px;
                cursor: pointer;
              }
            }
          }
        }

        .registerContent {
          .el-button {
            margin-top: 20px;
          }

          .codeContent {
            position: relative;

            .code {
              position: absolute;
              top: 0;
              right: 0px;
              display: flex;
              height: 100%;
              align-items: center;
              // height: 100%;
              // width: 100px;
              // border-left: 1px solid #E6E6E6;
              // text-align: center;
              // line-height: 45px;

              span {
                display: inline-block;
                line-height: 26px;
                width: 90px;
                height: 26px;
                border-left: 1px solid #e6e6e6;
                font-size: 12px;
                font-weight: 400;
                // color: var(--custom-color);
                padding-left: 14px;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }

          .successRegister {
            padding-top: 50px;

            .img {
              text-align: center;

              img {
                height: 100px;
                width: 100px;
              }

              .font {
                display: block;
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #303030;
                margin-bottom: 66px;
              }
            }
          }
        }

        .el-form-item {
          &:last-child {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .login2 {
    // padding-top: 100px;
    // background-image: url('../assets/home/backgroundImage.png');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    // background-color: antiquewhite;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    // padding: 180px;
    justify-content: center;

    .el-button {
      width: 100%;
      border-radius: 4px;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      // margin: auto;
      // display: flex;
      // justify-content: center;
      // position: relative;

      .container {
        width: 96%;
        margin: auto;

        .loginContent {
          margin-top: 5px;
          box-sizing: border-box;

          .first {
            margin-top: 20px;
          }

          .forgetPwd {
            text-align: right;
            font-size: 12px;
            font-weight: 400;
            color: var(--custom-color);
            cursor: pointer;
            margin-bottom: 20px;
          }

          .anotherLogin {
            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .solid {
                display: inline-block;
                height: 1px;
                width: 120px;
                background: #7d7d7d;
              }

              .name {
                text-align: center;
                display: inline-block;
                width: 100px;
                flex: 1;
                margin: 0px 20px;
                font-size: 12px;
                font-weight: 400;
                color: #7d7d7d;
              }
            }
          }

          .otherType {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            img {
              height: 50px;
              width: 50px;
              display: inline-block;
              margin: 0px 22px;

              &:hover {
                // height: 55px;
                // width: 55px;
                cursor: pointer;
              }
            }
          }
        }

        .registerContent {
          .el-button {
            margin-top: 20px;
          }

          .codeContent {
            position: relative;

            .code {
              position: absolute;
              top: 0;
              right: 0px;
              display: flex;
              height: 100%;
              align-items: center;
              // height: 100%;
              // width: 100px;
              // border-left: 1px solid #E6E6E6;
              // text-align: center;
              // line-height: 45px;

              span {
                display: inline-block;
                line-height: 26px;
                width: 90px;
                height: 26px;
                border-left: 1px solid #e6e6e6;
                font-size: 12px;
                font-weight: 400;
                // color: var(--custom-color);
                padding-left: 14px;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }

          .successRegister {
            padding-top: 50px;

            .img {
              text-align: center;

              img {
                height: 100px;
                width: 100px;
              }

              .font {
                display: block;
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #303030;
                margin-bottom: 66px;
              }
            }
          }
        }

        .el-form-item {
          &:last-child {
            margin-bottom: 10px;
          }
        }
      }

      .logo {
        margin: auto;
        margin-bottom: 10px;
        // position: absolute;
        // z-index: 2;
        text-align: center;
        line-height: 120px;
        // // position: absolute;
        // top: -100px;
        // left: calc(100% - 50% - 60px);
        width: 120px;
        height: 120px;
        background: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.36);
      }
    }
  }
}

.login {
  // background-image: url('../assets/home/backgroundImage.png');
  ::v-deep .impowerBox .qrcode {
    width: 100px !important;
  }

  .topName {
    font-size: 32px;
    font-weight: 600;
    color: var(--custom-color);
    // 		display: flex;
    // 		justify-content: center;
  }

  .hasCode {
    color: #7d7d7d;
  }

  .noCode {
    color: var(--custom-color);
  }
}

.login2 {
  // background-image: url('../assets/home/backgroundImage.png');
  .logo {
    img {
      // width: 100%;
      height: 60%;
      // width: 60%;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hasCode {
    color: #7d7d7d;
  }

  .noCode {
    color: var(--custom-color);
  }
}
</style>